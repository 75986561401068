<mat-card class="modal-activity-block__card">
    <mat-card-content class="modal-activity-block__card-content">
        <div>
            <mat-card-title class="modal-activity-block__card-title" [innerHTML]="instance.activityName"></mat-card-title>
            <mat-card-subtitle *ngIf="instance.activityDescription"
                               class="modal-activity-block__card-subtitle">
                {{instance.activityDescription}}
            </mat-card-subtitle>

            <div class="modal-activity-block__success-message">
                <ng-container [ngTemplateOutlet]="alwaysShowQuestionsCount ? questionsCount : questionsCountWithCompleteMessage"></ng-container>
            </div>

            <ng-template #questionsCountWithCompleteMessage>
                <ng-container *ngIf="instance.statusCode !== 'CREATED'">
                    <ng-container [ngTemplateOutlet]="isAllQuestionsCompleted ? completeBlock : questionsCount"></ng-container>

                    <ng-template #completeBlock>
                        <mat-icon inline>check_circle</mat-icon>
                        <span class="modal-activity-block__label"
                              [innerHTML]="'SDK.ModalActivityBlock.Complete' | translate">
                        </span>
                    </ng-template>
                </ng-container>
            </ng-template>

            <ng-template #questionsCount>
                <span
                  class="modal-activity-block__label"
                  [innerHTML]="
                    'SDK.ModalActivityBlock.NumQuestionsAnswered' | translate: {
                      number: instance.numQuestionsAnswered,
                      total: instance.numQuestions
                    }
                  "
                ></span>
            </ng-template>
        </div>

        <mat-card-actions class="modal-activity-block__card-buttons">
            <button mat-mini-fab
                    class="button_primary"
                    (click)="openEditDialog()"
                    [disabled]="!enabled">
                <img *ngIf="!readonly" src="assets/shared/pencil.svg"
                     [alt]="'SDK.ModalActivityBlock.EditButton' | translate">
                <mat-icon *ngIf="readonly">remove_red_eye</mat-icon>
            </button>
            <button mat-mini-fab
                    *ngIf="instance.canDelete && !readonly"
                    #delete_button
                    class="button_secondary"
                    (click)="openDeleteDialog()"
                    [disabled]="!enabled">
                <img src="assets/shared/trash.svg"
                     [alt]="'SDK.ModalActivityBlock.DeleteButton' | translate">
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>

<ng-template #edit_dialog>
    <div mat-dialog-title class="modal-activity-block__edit-dialog-title">
        <div [innerHTML]="instance.activityName"></div>
        <button mat-icon-button mat-dialog-close class="modal-activity-block__close-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="modal-activity-block__edit-dialog-content">
        <ddp-activity-section [section]="activityForm?.sections[0]"
                              [readonly]="readonly"
                              [studyGuid]="studyGuid"
                              [activityGuid]="instance.instanceGuid"
                              [validationRequested]="validationRequested"
        ></ddp-activity-section>

        <button mat-flat-button
                [disabled]="readonly"
                class="ctrl-btn button_primary modal-activity-block__save_activity"
                (click)="closeEditDialog()">
            <span [innerHTML]="'SDK.ModalActivityBlock.SaveButton' | translate"></span>
        </button>
    </mat-dialog-content>
</ng-template>
