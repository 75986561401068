<ddp-question-prompt *ngIf="!isGridLayout()" [block]="block"></ddp-question-prompt>

<ng-container [ngSwitch]="block.renderMode">
  <ng-container *ngSwitchCase="RenderMode.Inline">
    <ddp-activity-matrix-answer-table
      [block]="block"
      [renderGroups]="renderGroups"
      [readonly]="readonly"
      (valueChanged)="onValueChange($event)"
    ></ddp-activity-matrix-answer-table>
  </ng-container>

  <ng-container *ngSwitchCase="RenderMode.Modal">
    <button
      type="button"
      style="margin-bottom: 5px;"
      [ngClass]="{
        'button-filled': block.hasAnswer()
      }"
      class="button button--primary button_primary button_medium button--open-matrix-question-modal btn btn--primary"
      (click)="onOpenModalClick()"
    >
      {{ block.openBtnText }}
    </button>
  </ng-container>
</ng-container>
