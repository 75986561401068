<div class="confirm-dialog">
    <span class="confirm-dialog-title"
          [innerHTML]="title | translate">
    </span>
    <div *ngIf="content"
         [innerHTML]="content | translate : contentSubstitutions"
         class="confirm-dialog-content">
    </div>
    <div class="confirm-dialog-buttons">
        <button mat-flat-button class="ctrl-btn confirm-btn"
                [color]="confirmBtnColor"
                [mat-dialog-close]="true"
                [innerHTML]="confirmBtnText | translate">
        </button>
        <button mat-stroked-button class="ctrl-btn"
                mat-dialog-close
                [innerHTML]="cancelBtnText | translate">
        </button>
    </div>
</div>
