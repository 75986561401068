<ddp-question-prompt *ngIf="!isGridLayout()" [block]="block"></ddp-question-prompt>
<ng-container *ngFor="let childBlockRow of childQuestionBlocks; let row = index">
    <p *ngIf="row > 0" class="Title-text PageContent-subtitle Normal">
        <span>{{ block.additionalItemText }}</span>
        <button *ngIf="!readonly" mat-icon-button (click)="removeRow(row)">
            <mat-icon class="ddp-close-button">close</mat-icon>
        </button>
    </p>
    <div class="ddp-answer-container"
         [ngClass]="setOrientationClass(block.childOrientation)"
         [style.display]="isGridLayout() ? 'grid' : ''"
         [ngStyle]="{'grid-template-columns': gridLayoutColumnConfig()}">
        <ng-container *ngFor="let childBlock of childBlockRow; let column = index">
            <div *ngIf="block.tabularSeparator && column >= 1" class="tabular-separator">
                <span>{{block.tabularSeparator}}</span>
            </div>
            <ddp-activity-answer
                class="ddp-answer-field"
                [class.ddp-composite-text]="childBlock.questionType === 'text' && block.childOrientation !== 'VERTICAL'"
                [block]="childBlock"
                [readonly]="readonly"
                [layoutType]="layoutType"
                [validationRequested]="validationRequested"
                (valueChanged)="updateValue(row, column, $event)"
                (componentBusy)="componentBusy.next($event)">
            </ddp-activity-answer>
        </ng-container>
    </div>
</ng-container>
<button *ngIf="block.allowMultiple && !readonly"
        type="button"
        class="ButtonFilled ButtonFilled--white button button_medium button_secondary button_new-item"
        (click)="addBlankRow()">
    {{ block.addButtonText }}
</button>
