<main class="main">
    <section class="section intro-section">
        <div class="image-block">
            <img class="image-block__image" src="assets/images/about-page-broad-hero.jpg"
                [alt]="'AboutUsPage.Introduction.HeroImgAlt' | translate">
        </div>
        <div class="text-block">
            <p class="text-block__text no-margin light" [innerHTML]="'AboutUsPage.Introduction.Text.Main' | translate">
            </p>
            <div class="text-block__link">
                <a class="cmi-link" [href]="cmiUrl" target="_blank"
                    [innerHTML]="'AboutUsPage.Introduction.Text.Link' | translate">
                </a>
            </div>
        </div>
        <div class="title-block">
            <h1 class="section-title" translate>
                AboutUsPage.Introduction.Title
            </h1>
        </div>
    </section>
    <section class="section">
        <div class="content content_tight cmi-block">
            <div class="cmi-block__logo">
                <a class="cmi-block__link" [href]="cmiUrl" target="_blank">
                    <img lazy-resource src="assets/images/logo-count-me-in.svg" [alt]="'Common.Alts.CMI' | translate">
                </a>
            </div>
            <p>
                <a class="Link italic" [href]="cmiUrl" target="_blank" translate>Common.CMI</a>
                <span translate>AboutUsPage.CMI.Paragraph1</span>
            </p>
            <p translate>AboutUsPage.CMI.Paragraph2</p>
        </div>
    </section>
    <section class="section">
        <div class="content content_tight">
            <h2 class="section-title" translate>
                AboutUsPage.PAC.Title
            </h2>
            <p [innerHTML]="'AboutUsPage.PAC.Text' | translate"></p>
            <ng-container *ngFor="let member of 'AboutUsPage.PAC.Members' | translate">
                <div class="pac-member">
                    <ng-container *ngIf="member.Image">
                        <img lazy-resource class="pac-member__image" [src]="member.Image" [alt]="member.Alt">
                    </ng-container>
                    <h3 class="no-margin pac-member__title">{{member.Name}}</h3>
                    <ng-container *ngFor="let paragraph of member.Bio">
                        <p>{{paragraph}}</p>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </section>
    <section class="section">
        <div class="content content_medium sac-block">
            <h2 class="section-title" translate>
                AboutUsPage.SAC.Title
            </h2>
            <div class="sac-members">
                <ng-container *ngFor="let member of 'AboutUsPage.SAC.Members' | translate">
                    <div class="sac-member">
                        <img lazy-resource class="sac-member__image" [src]="member.Image" [alt]="member.Alt">
                        <div>
                            <ng-container *ngIf="member.Url; else text">
                                <a class="sac-member__link" [href]="member.Url" target="_blank">{{member.Name}}</a>
                            </ng-container>
                            <ng-template #text>
                                <p class="sac-member__text">{{member.Name}}</p>
                            </ng-template>
                            <div class="affiliations">
                                <ng-container *ngFor="let affiliation of member.Affiliations">
                                    <p class="affiliations__text">{{affiliation}}</p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>
    <section class="section learn-more-section">
        <app-learn-more-section></app-learn-more-section>
    </section>
</main>