export enum WorkflowState {
    ACTIVITY = 'ACTIVITY',
    DASHBOARD = 'DASHBOARD',
    DONE = 'DONE',
    RETURN_USER = 'RETURN_USER',
    START = 'START',
    THANK_YOU = 'THANK_YOU',
    INTERNATIONAL_PATIENTS = 'INTERNATIONAL_PATIENTS',
    MAILING_LIST = 'MAILING_LIST',
    UNKNOWN = 'UNKNOWN',
    REGISTRATION = 'REGISTRATION',
    PARTICIPANT_LIST = 'PARTICIPANT_LIST',
    STUDY_REDIRECT = 'STUDY_REDIRECT'
}
