<ddp-question-prompt *ngIf="!isGridLayout()" [block]="block"></ddp-question-prompt>

<mat-form-field>
  <mat-label *ngIf="block.label" [innerHTML]="block.label"></mat-label>

  <mat-select
    [placeholder]="placeholder || block.placeholder"
    [disabled]="readonly"
    [value]="block.answer"
    [compareWith]="compare"
    (selectionChange)="onSelectionChange($event.value)"
    (opened)="onOpen()"
  >
    <mat-option *ngFor="let option of options$ | async" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
