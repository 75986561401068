export const ActivityGuids = {
    Dashboard: 'DASHBOARD',
    AboutYou: 'ABOUTYOU',
    AboutChild: 'ABOUTCHILD',
    Consent: 'CONSENT',
    ConsentAssent: 'CONSENT_ASSENT',
    ParentalConsent: 'PARENTAL_CONSENT',
    ReleaseMinor: 'RELEASE_MINOR',
    Release: 'RELEASE'
};
