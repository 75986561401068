<ddp-question-prompt *ngIf="!isGridLayout()" [block]="block"></ddp-question-prompt>

<div class="file-requirements" *ngIf="block.maxFileSize || block.mimeTypes?.length">
    <div class="file-max-size" *ngIf="block.maxFileSize">
        {{'SDK.FileUpload.MaxSize' | translate : { maxSize: block.maxFileSize | fileSize} }}
    </div>
    <div class="file-types" *ngIf="block.mimeTypes?.length">
        {{'SDK.FileUpload.AllowedFileTypes' | translate : { allowedFileTypes: getAllowedTypes(block.mimeTypes)} }}
    </div>
</div>

<div class="file-upload-content" [class.readonly]="readonly">
    <input type="file" class="file-input"
           multiple
           [accept]="(block.mimeTypes || []).join(',')"
           (click)="fileUpload.value = null;"
           (change)="onFilesSelected($event)" #fileUpload>

    <div class="file-select">
        <div class="drop-block"
             dropFileToUpload
             (filesDropped)="!readonly && onFilesDropped($event)"
             (click)="!readonly && fileUpload.click()">
            <mat-icon class="upload-icon" color="primary">file_upload</mat-icon>
            <div class="drop-block-text" translate>SDK.FileUpload.ChooseFile</div>
        </div>
    </div>

    <div class="uploaded-file">
        <ng-container *ngIf="isLoading">
            <button mat-icon-button color="primary"
                    class="cancel-upload-btn"
                    [attr.aria-label]="'SDK.FileUpload.CancelUpload' | translate"
                    (click)="cancelUpload()"
                    #cancelUploadBtn>
                <mat-icon>cancel</mat-icon>
            </button>
            <div class="title">{{'SDK.FileUpload.Uploading' | translate }}</div>
            <ddp-loading [loaded]="!isLoading" class="loading-bar"></ddp-loading>
        </ng-container>

        <ddp-activity-file-answer-success *ngIf="uploadedFiles?.length > 0"></ddp-activity-file-answer-success>

        <mat-chip-list *ngIf="uploadedFiles && uploadedFiles.length > 0">
            <ng-container *ngFor="let uploadedFile of uploadedFiles; let i = index">
                <mat-chip
                    class="uploaded-file-chip"
                    color="primary" selected
                    (removed)="undoUploadedFile(i)"
                    #uploaded>
                    <span class="file-name">{{ uploadedFile?.fileName }}</span>
                    <span class="file-size">{{'SDK.FileUpload.Size' | translate : { size: uploadedFile?.fileSize | fileSize} }}</span>
                    <button matChipRemove *ngIf="!readonly"
                            class="cancel-btn"
                            mat-icon-button
                            [attr.aria-label]="'SDK.FileUpload.RemoveUploadedFile' | translate"
                            #undoUploadBtn>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </ng-container>
        </mat-chip-list>
    </div>
</div>

<ddp-validation-message [message]="errorMessage"></ddp-validation-message>
