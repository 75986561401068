export const AppRoutes = {
    Activity: 'activity',
    ActivityId: 'activity/:id',
    ActivityLinkId: 'activity-link/:id',
    Error: 'error',
    LoginLanding: 'login-landing',
    LoginLandingMode: 'login-landing/:mode',
    LocalAuth: 'auth',
    AboutYou: 'about-you',
    AboutChild: 'about-child',
    Consent: 'consent',
    ConsentAssent: 'consent-assent',
    ParentalConsent: 'parental-consent',
    ReleaseMinor: 'release-minor-survey',
    Release: 'release-survey',
    Dashboard: 'dashboard',
    Password: 'password',
    SessionExpired: 'session-expired',
    AdminSessionExpired: 'admin-session-expired',
    PasswordResetDone: 'password-reset-done',
    CountMeIn: 'count-me-in',
    AboutUs: 'about-us',
    Data: 'data',
    MoreDetails: 'more-details',
    StayInformed: 'stay-informed',
    JoinList: 'join-list',
    ThankYou: 'thank-you',
    ProxyThankYou: 'proxy-thank-you',
    Verify: 'verify',
    Accept: 'accept',
    MailingList: 'updates',
    Prism: 'prism',
    AdminLanding: 'admin-login-landing'
};
