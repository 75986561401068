<div class="margin-bottom" *ngFor="let block of section.blocks">
    <ng-container *ngIf="block.displayNumber; then numbered else notNumbered">
    </ng-container>

    <ng-template #numbered>
        <ol class="ddp-list">
            <li class="ddp-li" [value]="block.displayNumber">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </li>
        </ol>
    </ng-template>

    <ng-template #notNumbered>
        <div class="ddp-single-question">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
    </ng-template>

    <ng-template #content>
        <div *ngIf="shouldBlockBeShown(block, BlockType.Conditional)">
            <ddp-conditional-block [block]="block"
                                   [readonly]="readonly || !block.enabled"
                                   [validationRequested]="validationRequested"
                                   [studyGuid]="studyGuid"
                                   [activityGuid]="activityGuid">
            </ddp-conditional-block>
        </div>
        <div *ngIf="shouldBlockBeShown(block, BlockType.Question)">
            <ddp-activity-question [block]="block"
                                   [readonly]="readonly || !block.enabled"
                                   [validationRequested]="validationRequested"
                                   [studyGuid]="studyGuid"
                                   [activityGuid]="activityGuid"
                                   (componentBusy)="componentBusy.emit($event)">
            </ddp-activity-question>
        </div>
        <div *ngIf="shouldBlockBeShown(block, BlockType.Institution)">
            <ddp-institutions-form [block]="block"
                                   [studyGuid]="studyGuid"
                                   [readonly]="readonly || !block.enabled"
                                   [validationRequested]="validationRequested"
                                   (validStatusChanged)="updateValidationStatusInSection(idPrefix.block + block.id, $event)"
                                   (componentBusy)="componentBusy.emit($event)">
            </ddp-institutions-form>
        </div>
        <div *ngIf="shouldBlockBeShown(block, BlockType.MailAddress)">
            <ddp-address-embedded [block]="block"
                                  [country]="config.supportedCountry"
                                  [readonly]="readonly || !block.enabled"
                                  [activityGuid]="activityGuid"
                                  (validStatusChanged)="updateValidationStatusInSection(idPrefix.block + block.id, $event)"
                                  [validationRequested]="validationRequested"
                                  (componentBusy)="componentBusy.emit($event)">
            </ddp-address-embedded>
        </div>
    </ng-template>

    <div *ngIf="shouldBlockBeShown(block, BlockType.Content)">
        <ddp-activity-content [studyGuid]="studyGuid"
                              [activityGuid]="activityGuid"
                              [activityCode]="activityCode"
                              [activityStatusCode]="activityStatusCode"
                              [section]="section"
                              [block]="block"
                              [studyEmail]="studyEmail"
                              [studyPhone]="studyPhone"
                              (componentBusy)="componentBusy.emit($event)"></ddp-activity-content>
    </div>
    <div *ngIf="shouldBlockBeShown(block, BlockType.Group)">
        <ddp-group-block [block]="block"
                         [readonly]="readonly || !block.enabled"
                         [validationRequested]="validationRequested"
                         [studyGuid]="studyGuid"
                         [activityGuid]="activityGuid">
        </ddp-group-block>
    </div>
    <div *ngIf="shouldBlockBeShown(block, BlockType.Activity)">
        <ddp-activity-block [block]="block"
                            [enabled]="block.enabled"
                            [readonly]="readonly"
                            [validationRequested]="validationRequested"
                            [studyGuid]="studyGuid"
                            [parentActivityInstanceGuid]="activityGuid"
                            (validStatusChanged)="updateValidationStatusInSection(idPrefix.instance + $event.id, $event.value)"
                            (embeddedComponentBusy)="componentBusy.emit($event)"
                            (blockVisibilityChanged)="onBlockVisibilityChanged($event)">
        </ddp-activity-block>
    </div>
    <ddp-tabular-block *ngIf="shouldBlockBeShown(block, BlockType.Tabular)"
                       [block]="block"
                       [readonly]="readonly || !block.enabled"
                       [validationRequested]="validationRequested"
                       [studyGuid]="studyGuid"
                       [activityGuid]="activityGuid">
    </ddp-tabular-block>
</div>
