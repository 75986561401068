<div class="dialog-container"> 
    <div class="dialog-container-header">
      <div class="dialog-container-title">
        {{data.block.modalTitle}}
      </div>
      <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>  
    </div>

  <ddp-activity-matrix-answer-table
    [block]="data.block"
    [renderGroups]="data.renderGroups"
    [readonly]="data.readonly"
    (valueChanged)="data.valueChanged($event)"
  ></ddp-activity-matrix-answer-table>
</div>
