<footer class="footer">
    <div class="footer-content">
        <div class="nav">
            <nav>
                <ul class="list">
                    <li class="list__item">
                        <button class="simple-button footer-button" (click)="scrollToTop()">
                            <mat-icon>arrow_drop_up</mat-icon>
                            <span translate>Navigation.BackToTop</span>
                        </button>
                    </li>
                    <li class="list__item">
                        <a class="footer-link" [routerLink]="'/' + appRoutes.MoreDetails"
                            routerLinkActive="footer-link_active" translate>Navigation.FAQs</a>
                    </li>
                    <li class="list__item">
                        <a class="footer-link" [routerLink]="'/' + appRoutes.AboutUs"
                            routerLinkActive="footer-link_active" translate>Navigation.AboutUs</a>
                    </li>
                    <li class="list__item">
                        <button class="simple-button footer-button" (click)="openJoinMailingList()">
                            <span translate>Common.Buttons.JoinMailingList</span>
                        </button>
                    </li>
                    <li class="list__item">
                        <a class="footer-link" [routerLink]="'/' + appRoutes.Data" routerLinkActive="footer-link_active"
                            translate>Navigation.Data</a>
                    </li>
                </ul>
            </nav>
            <div class="info">
                <div class="info__line"></div>
                <ng-container *ngTemplateOutlet="text"></ng-container>
            </div>
        </div>
        <div class="contacts">
            <div class="sections">
                <p class="sections__item" translate>Footer.SocialMedia</p>
                <p class="sections__item" translate>Footer.ContactUs</p>
            </div>
            <div>
                <div class="social-media">
                    <p class="social-media__title" translate>Footer.SocialMedia</p>
                    <div class="social-media__links">
                        <a class="footer-link social-media__facebook medium"
                            [href]="'https://www.facebook.com/' + facebookAccount" target="_blank"
                            translate>Footer.Facebook</a>
                        <a class="footer-link social-media__twitter medium"
                           [href]="'https://twitter.com/' + twitterAccount" target="_blank"
                           translate>Footer.Twitter</a>
                        <a class="footer-link social-media__instagram medium"
                           [href]="'https://instagram.com/' + instagramAccount" target="_blank"
                           translate>Footer.Instagram</a>
                    </div>
                </div>
                <div>
                    <div class="contact-us">
                        <p class="contact-us__title contact-us__title_last" translate>Footer.ContactUs</p>
                        <a class="footer-link contact-us__item medium" [href]="'mailto:' + infoEmail">{{infoEmail}}</a>
                        <a class="footer-link contact-us__item contact-us__item_phone medium"
                            [href]="'tel:' + phone">{{phone}}</a>
                    </div>
                    <address>
                        <p class="address" [innerHTML]="'Footer.Address' | translate"></p>
                    </address>
                </div>
            </div>
        </div>
        <div class="mobile-info">
            <ng-container *ngTemplateOutlet="text"></ng-container>
        </div>
    </div>
</footer>

<ng-template #text>
    <p class="cmi-text">
        <span translate>Footer.Text.Part1</span>
        <a class="cmi-text__link" [href]="cmiUrl" target="_blank" translate>Common.CMI</a>,
        <span translate>Footer.Text.Part2</span>
    </p>
</ng-template>
